import type React from 'react'
import styled from 'styled-components'

import {tokens} from '../../tokens'
import {useLocalisation} from '../../utils/localisation'
import {px} from '../../utils/px'
import {Skeleton} from '../skeleton'
import {VisuallyHidden} from '../visually-hidden'

export type BadgeVariants = 'neutral' | 'positive' | 'negative' | 'warning' | 'info' | 'discover'

export interface BadgeProps extends React.HTMLAttributes<HTMLSpanElement> {
    /**
     * Choose what status the badge should represent
     */
    variant: BadgeVariants
    /**
     * Provide the badge with a label
     */
    children: React.ReactNode
    /**
     * Add a border to the badge to make it stand out more from the background, especially on gray backgrounds.
     * @default false
     */
    loud?: boolean
    /**
     * Show a skeleton loading state
     * @default false
     */
    skeleton?: boolean
    /**
     * Add an icon to the left of the label
     */
    Icon?: React.FC<{size?: any; color?: any}>
    /**
     * Override the visually hidden prefix label
     */
    visuallyHiddenPrefix?: string
}

const getContentColor = ($variant: BadgeVariants) => {
    if ($variant === 'positive') {
        return tokens.colorContentPositiveLoud
    } else if ($variant === 'negative') {
        return tokens.colorContentNegativeLoud
    } else if ($variant === 'warning') {
        return tokens.colorContentWarningLoud
    } else if ($variant === 'info') {
        return tokens.colorContentInfoLoud
    } else if ($variant === 'discover') {
        return tokens.colorContentDiscoverLoud
    } else if ($variant === 'neutral') {
        return tokens.colorContentNeutral
    }

    return tokens.colorContentStatic
}

const getBackgroundColor = ($variant: BadgeVariants) => {
    if ($variant === 'positive') {
        return tokens.colorBackgroundPositiveQuiet
    } else if ($variant === 'negative') {
        return tokens.colorBackgroundNegativeQuiet
    } else if ($variant === 'warning') {
        return tokens.colorBackgroundWarningQuiet
    } else if ($variant === 'info') {
        return tokens.colorBackgroundInfoQuiet
    } else if ($variant === 'discover') {
        return tokens.colorBackgroundDiscoverQuiet
    } else if ($variant === 'neutral') {
        return tokens.colorBackgroundNeutralQuiet
    }

    return tokens.colorBackgroundStaticLouder
}

const getBorderColor = ($variant: BadgeVariants) => {
    if ($variant === 'positive') {
        return tokens.colorBorderPositive
    } else if ($variant === 'negative') {
        return tokens.colorBorderNegative
    } else if ($variant === 'warning') {
        return tokens.colorBorderWarning
    } else if ($variant === 'info') {
        return tokens.colorBorderInfo
    } else if ($variant === 'discover') {
        return tokens.colorBorderDiscover
    } else if ($variant === 'neutral') {
        return tokens.colorBorderNeutral
    }

    return tokens.colorBorderStatic
}

const Wrapper = styled('span')<{$variant: BadgeVariants; $loud?: Boolean}>`
    box-sizing: border-box;
    min-height: ${px(22)};
    display: inline-flex;
    align-items: center;
    padding: ${tokens.spacing2} ${tokens.spacing8};
    gap: ${tokens.spacing4};
    border: ${tokens.sizeBorderDefault} solid
        ${({$variant, $loud}) => ($loud ? getBorderColor($variant) : 'transparent')};
    border-radius: ${tokens.arc99999};
    background-color: ${({$variant}) => getBackgroundColor($variant)};
    font-weight: ${tokens.fontWeightMedium};
    font-size: ${tokens.fontSmall};
    color: ${({$variant}) => getContentColor($variant)};
    user-select: none;
    white-space: nowrap;
`
export const Badge = ({
    variant = 'neutral',
    children,
    loud = false,
    skeleton = false,
    Icon,
    visuallyHiddenPrefix,
    ...props
}: BadgeProps) => {
    const translations = useLocalisation()

    return (
        <Skeleton loading={skeleton}>
            <Wrapper $variant={variant} $loud={loud} data-telescope="badge" {...props}>
                {Icon && <Icon size={16} color={getContentColor(variant)} />}
                <VisuallyHidden>
                    {visuallyHiddenPrefix ?? `${translations.statusLabels[variant]}: `}
                </VisuallyHidden>
                {children}
            </Wrapper>
        </Skeleton>
    )
}
