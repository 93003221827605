/**
 * @overview Date Picker
 * @stage Proposed
 * @author Maciek (@pekala)
 * @designer Annika
 * @spec https://www.figma.com/file/w3PQGgCdm7wr9pOT3EdQa2/Bills-web-components?node-id=1%3A2904
 */

import type {
    CalendarProps,
    CalendarTileProperties,
    OnChangeDateCallback,
    OnChangeDateRangeCallback
} from 'react-calendar'
import Calendar from 'react-calendar'
import styled, {css} from 'styled-components'

import {ArrowLeft, ArrowRight} from '@pleo-io/telescope-icons'

import {customColorSchemeTokens, tokens} from '../../tokens'
import {px} from '../../utils/px'
import {focusRing} from '../focus-ring'

/**
 * react-calendar date-picker, styled for Pleo and with a few default settings set
 * @see {@link https://github.com/wojtekmaj/react-calendar|react-calendar docs}
 * @param props react-calendar prop
 */

export interface DatePickerProps extends CalendarProps {
    /**
     * Whether the user should select two dates forming a range instead of just one. Note: This feature will make React-Calendar return an array with two dates regardless of returnValue setting.
     * @default false
     */
    selectRange?: boolean

    /**
     * Whether to call onChange with only partial result given selectRange prop.
     * @default false
     */
    allowPartialRange?: boolean

    /**
     * Whether week numbers should be shown at the left of MonthView or not.
     * @default false
     */
    showWeekNumbers?: boolean

    /**
     * Whether to show two months/years/… at a time instead of one.
     * @default false
     */
    showDoubleView?: boolean

    /**
     * The least detailed view that the user should see.
     * @default 'century'
     */
    minDetail?: 'century' | 'decade' | 'year' | 'month'

    /**
     * Maximum date that the user can select. Periods partially overlapped by maxDate will also be selectable, although date-picker will ensure that no later date is selected.
     *
     */
    maxDate?: Date

    /**
     * Minimum date that the user can select. Periods partially overlapped by minDate will also be selectable, although date-picker will ensure that no earlier date is selected.
     *
     */
    minDate?: Date

    /**
     * Function called when the user clicks an item (day on month view, month on year view and so on) on the most detailed view available. Example : (value, event) => alert('New date is: ', value)
     *
     */
    onChange?: OnChangeDateCallback | OnChangeDateRangeCallback | undefined

    /**
     * Calendar value. Can be either one value or an array of two values. It can be just a date or an array of dates example [new Date(2017, 0, 1), new Date(2017, 7, 1)]
     *
     */
    value?: Date | Date[] | null | undefined

    /**
     * Pass a function to determine if a certain day should be displayed as disabled. Example: tileDisabled={({date}) => date > new Date()}
     *
     */
    tileDisabled?: ((props: CalendarTileProperties) => boolean) | undefined

    /**
     * Locale for calendar
     */
    locale?: string
}

export const DatePicker = ({...props}: DatePickerProps) => (
    <StyledCalendar
        // eslint-disable-next-line @pleo-io/telescope/icon-size
        nextLabel={<ArrowRight size={20} />}
        // eslint-disable-next-line @pleo-io/telescope/icon-size
        prevLabel={<ArrowLeft size={20} />}
        prev2Label={null}
        next2Label={null}
        minDetail="decade"
        showNeighboringMonth={false}
        prevAriaLabel={'date-picker-previous'}
        nextAriaLabel={'date-picker-next'}
        {...props}
    />
)

DatePicker.displayName = 'DatePicker'

/////////////////////////////////////////////////////////////////////////////////

const borderOffset = -1
const tileSize = 40
const tabletUp = 768

const navigationStyles = css`
    .react-calendar__navigation__arrow {
        flex-grow: 0;
        justify-content: space-around;
        box-sizing: border-box;
        width: ${px(32)};
        height: ${px(32)};
        color: ${tokens.colorContentInteractiveQuiet};
        background: none;
        border-radius: ${tokens.circle};

        &:disabled {
            color: ${tokens.colorContentInteractiveDisabled};
        }

        &:enabled:hover {
            color: ${tokens.colorContentInteractiveHover};
            background-color: ${tokens.colorBackgroundInteractiveHover};
        }
    }

    .react-calendar__navigation__label {
        flex: 1;
        justify-content: space-around;
        margin: 0 ${tokens.spacing10};
        padding: ${tokens.spacing6} ${tokens.spacing12};
        color: ${tokens.colorContentInteractive};
        font-weight: ${tokens.fontWeightBold};
        font-size: ${tokens.fontMedium};
        line-height: ${tokens.lineHeight1};
        background: none;
        border-radius: ${tokens.arc8};

        &:enabled:hover {
            color: ${tokens.colorContentInteractiveHover};
            background-color: ${tokens.colorBackgroundInteractiveHover};
        }
    }

    .react-calendar__navigation__label__divider {
        display: none;
    }

    .react-calendar__navigation__label__labelText--from {
        width: 50%;
    }

    .react-calendar__navigation__label__labelText--to {
        width: 50%;
    }

    .react-calendar__navigation {
        align-items: center;
        justify-content: center;
        margin-bottom: ${tokens.spacing6};
    }

    @media (min-width: ${tabletUp}) {
        .react-calendar__navigation__label {
            padding: ${tokens.spacing10} ${tokens.spacing20};
            font-size: ${tokens.fontXLarge};
        }
    }
`

const weekNumberStyles = css`
    .react-calendar__month-view__weekNumbers .react-calendar__tile {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: ${tokens.fontWeightBold};
        font-size: ${tokens.fontSmall};
        border: none;
    }
`

const weekdayStyles = css`
    .react-calendar__month-view__weekdays__weekday {
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${px(tileSize)};
        height: ${px(tileSize)};
        color: ${tokens.colorContentStaticQuiet};
        font-size: ${tokens.fontLarge};

        abbr {
            text-decoration: none;
            border-bottom: none;
        }
    }
`

const tileStyles = css`
    .react-calendar__tile {
        position: relative;
        width: ${px(tileSize)};
        max-width: 100%;
        height: ${px(tileSize)};
        margin-top: ${px(borderOffset)};
        margin-left: ${px(borderOffset)};
        color: ${tokens.colorContentInteractive};
        background-color: ${tokens.colorBackgroundInteractive};
        border: ${tokens.borderInteractiveQuiet};
        transition: ${`background-color ${tokens.motionWithinSmallShort}`};

        &:disabled {
            color: ${tokens.colorContentInteractiveDisabled};
            cursor: not-allowed;
        }

        &:focus {
            z-index: ${tokens.zIndexSurface};
        }

        &:enabled:hover {
            color: ${tokens.colorContentInteractiveHover};
            background-color: ${tokens.colorBackgroundInteractiveHover};
        }
    }

    .react-calendar__month-view__days__day--neighboringMonth {
        color: ${tokens.colorContentInteractiveQuiet};
        border: ${tokens.borderInteractiveQuiet};
    }

    .react-calendar__tile--now {
        font-weight: ${tokens.fontWeightSemibold};
    }

    .react-calendar__tile--active {
        color: ${tokens.colorContentInteractive};
        font-weight: ${tokens.fontWeightMedium};
        background-color: ${customColorSchemeTokens.colorBackgroundDatePickerActiveTile};

        &:enabled:hover {
            color: ${tokens.colorContentInteractiveHover};
        }
    }

    .react-calendar__tile--hasActive,
    .react-calendar__tile--rangeBothEnds,
    .react-calendar__tile--rangeStart,
    .react-calendar__tile--rangeEnd {
        color: ${tokens.colorContentInteractiveSelected};
        background-color: ${tokens.colorBackgroundInteractiveSelected};

        &:enabled:hover {
            color: ${tokens.colorContentInteractiveSelectedHover};
            background-color: ${tokens.colorBackgroundInteractiveSelectedHover};
        }
    }
`

const getMonthWidth = (props: DatePickerProps) => {
    return 20 + (props.showWeekNumbers ? 8 : 7) * tileSize
}
const StyledCalendar = styled(Calendar)`
    width: ${(props) => px(getMonthWidth(props))};
    max-width: 100%;
    font-size: ${tokens.fontLarge};
    font-family: inherit;

    &,
    & *,
    & *::before,
    & *::after {
        box-sizing: border-box;
    }

    /* For more information: https://github.com/wojtekmaj/react-calendar/issues/462#issuecomment-772388623 */
    .react-calendar__month-view__days__day--neighboringMonth {
        visibility: ${(props) => !props.showNeighboringMonth && 'hidden'};
    }

    button {
        margin: 0;
        border: none;
        outline: none;
        font-size: inherit;
        font-family: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        ${focusRing('inset')}
    }

    button:enabled {
        cursor: pointer;
    }

    ${weekdayStyles}
    ${navigationStyles}
    ${tileStyles}
    ${weekNumberStyles}

    @media (min-width: ${tabletUp}px) {
        &.react-calendar--doubleView {
            width: calc(${(props) => px(2 * getMonthWidth(props))} + ${tokens.spacing12});
        }

        &.react-calendar--doubleView .react-calendar__viewContainer {
            display: flex;
            margin: -${tokens.spacing12};

            & > * {
                width: 50%;
                margin: ${tokens.spacing12};
            }
        }
    }
`
