import type {FC} from 'react'
import type React from 'react'
import {useCallback, useEffect, useRef, useState} from 'react'
import styled, {css} from 'styled-components'

import {tokens} from '../../tokens'
import {useLocalisation} from '../../utils/localisation'
import {px} from '../../utils/px'
import {focusRingWidth} from '../focus-ring'
import {Label, useFormControlContext, useFormControlId} from '../form-control/form-control'
import {Text} from '../text'
import {VisuallyHidden} from '../visually-hidden'

type ComponentWrapperProps = Pick<TextareaProps, 'className'>

const ComponentWrapper = styled.div<ComponentWrapperProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacing6};
    width: 100%;
`

export interface BaseTextareaProps {
    /**
     * Name is used for accessibility and is required
     */
    name: string

    /**
     * Current value of the textarea
     * @default null
     */
    value?: string | null

    /**
     * Should it render the input as holding an invalid value
     * @default false
     */
    isInvalid?: boolean

    /**
     * Adds aria-required="true" to the textarea
     */
    isRequired?: boolean

    /**
     * Render prop for error message
     */
    renderError?: Function

    /**
     * Should the component be disabled. Meaning no interaction possible
     */
    disabled?: boolean

    /**
     * Display a label above the textarea
     */
    label?: string

    /**
     * Display count value in lower right corner
     * @default false
     */
    showCounter?: boolean

    /**
     * Set a fixed height with a scrollbar
     */
    fixedHeight?: string

    /**
     * aria-label can be added to the textarea for further details,
     * though accessibility should be handled out of the box by using the name property
     */
    'aria-label'?: string

    /**
     * className is used to override styles of the component
     */
    className?: string
}

export interface TextareaProps
    extends BaseTextareaProps,
        Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'value' | 'name'>,
        React.RefAttributes<HTMLTextAreaElement> {}

const StyledTextarea = styled.textarea<{$fixedHeight?: string}>`
    grid-area: input;
    box-sizing: border-box;
    width: 100%;
    min-height: ${px(24)};
    text-indent: ${px(1)};
    color: ${tokens.colorContentInteractive};
    font-weight: inherit;
    font-size: ${tokens.fontMedium};
    line-height: ${tokens.lineHeight2};
    font-family: inherit;
    background-color: ${tokens.colorBackgroundInteractive};
    border: none;
    outline: none;
    transition: ${tokens.motionWithinSmallShort};
    appearance: none;
    resize: none;
    overflow-y: hidden;

    &:hover {
        color: ${({disabled}) => !disabled && tokens.colorContentInteractiveHover};
    }

    &::placeholder {
        color: ${tokens.colorContentInteractivePlaceholder};
    }

    ${({$fixedHeight}) =>
        $fixedHeight &&
        `
            height: ${$fixedHeight};
            overflow-y: auto;
        `}

    ${({disabled}) =>
        disabled &&
        `
            cursor: default;
            color: ${tokens.colorContentInteractiveDisabled};
            background-color: ${tokens.colorBackgroundInteractiveDisabled};

            &::placeholder {
                color: ${tokens.colorContentInteractiveDisabled};
            }
        `}
`
interface TextareaWrapperProps {
    $isInvalid?: boolean
    $disabled?: boolean
}

const focusBorders = css<TextareaWrapperProps>`
    &:focus-within {
        border-color: transparent;

        /* Fallback for Windows High Contrast Mode, as it hides box-shadows */
        outline: ${focusRingWidth} solid transparent;
        box-shadow: ${(props) =>
            `0 0 0 ${focusRingWidth} ${
                props.$isInvalid ? tokens.colorShadowFocusInvalid : tokens.colorShadowFocus
            }`};
        transition: ${tokens.motionWithinSmallLong};
    }

    &:hover:focus-within {
        border-color: transparent;
    }

    &:hover:not(:focus-within) {
        border: ${tokens.sizeBorderDefault} solid
            ${(props) =>
                props.$isInvalid ? tokens.colorShadowFocusInvalid : tokens.colorShadowFocus};
    }
`

const TextareaWrapper = styled.div<TextareaWrapperProps>`
    position: relative;
    display: grid;
    grid-template-areas: 'input postfix';
    grid-template-columns: 1fr auto;
    align-items: center;
    overflow: hidden;
    transition: ${tokens.motionWithinSmallShort};
    padding: ${tokens.spacing8} ${tokens.spacing12};
    padding-right: ${tokens.spacing20};
    border: ${(props) => {
        if (props.$disabled) {
            return `${tokens.borderInteractiveDisabled}`
        }

        if (props.$isInvalid) {
            return `${tokens.borderNegative}`
        }

        return `${tokens.borderInteractiveQuiet}`
    }};
    border-radius: ${tokens.arc8};
    background-color: ${(props) =>
        props.$disabled
            ? tokens.colorBackgroundInteractiveDisabled
            : tokens.colorBackgroundInteractive};

    ${({$disabled}) => !$disabled && focusBorders}
`

// This is needed to default the textarea to height 40 on the wrapper. Similar to Input
const MIN_HEIGHT = 24

export const Textarea: FC<TextareaProps> = ({
    name,
    onChange,
    showCounter = false,
    value,
    label,
    className,
    isInvalid,
    isRequired,
    renderError,
    maxLength,
    disabled,
    fixedHeight,
    'aria-label': ariaLabel,
    id: inputId,
    ...props
}) => {
    const translations = useLocalisation()
    const id = useFormControlId(inputId)
    const {hintTextId, errorId} = useFormControlContext()
    const [counter, setCounter] = useState<number>(0)
    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    const handleResize = useCallback(() => {
        if (textAreaRef?.current && !fixedHeight) {
            /* Reset the height
             * This is needed to resize the textarea vertically
             **/
            textAreaRef.current.style.height = ''

            const scrollHeight = textAreaRef.current.scrollHeight
            const newHeight = Math.max(scrollHeight, MIN_HEIGHT)
            textAreaRef.current.style.height = newHeight + 'px'
        }
    }, [fixedHeight, textAreaRef])

    const updateAndResize = useCallback(() => {
        const updateValue = value ?? textAreaRef?.current?.value

        const toNumber = Number(updateValue?.length)

        const count = isNaN(toNumber) ? 0 : toNumber

        setCounter(maxLength ? maxLength - count : count)
        handleResize()
    }, [value, maxLength, handleResize])

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [handleResize])

    useEffect(() => {
        updateAndResize()
        const current = textAreaRef?.current
        current?.addEventListener('input', updateAndResize)

        return () => current?.removeEventListener('input', updateAndResize)
    }, [textAreaRef, updateAndResize])

    const isValueLengthOverLimit = Boolean(maxLength && value && value.length > maxLength)

    const counterId = `counter-${id}`
    const maxCharactersId = `max-characters-${id}`

    return (
        <ComponentWrapper className={className} data-telescope="textarea">
            {label && (
                <Label
                    as="label"
                    // Will be fixed in https://linear.app/pleo/issue/WEB-789/replace-react-docgen-typescript-with-react-docgen
                    // @ts-expect-error htmlFor prop missing
                    htmlFor={id}
                >
                    {label}
                </Label>
            )}
            <TextareaWrapper $isInvalid={isInvalid || isValueLengthOverLimit} $disabled={disabled}>
                <StyledTextarea
                    id={id}
                    name={name}
                    rows={1}
                    aria-controls={showCounter && !!maxLength ? counterId : undefined}
                    aria-label={ariaLabel}
                    aria-required={isRequired}
                    value={value === null ? '' : value}
                    onChange={onChange}
                    maxLength={maxLength}
                    disabled={disabled}
                    ref={textAreaRef}
                    $fixedHeight={fixedHeight}
                    aria-describedby={`${errorId ?? ''} ${hintTextId ?? ''} ${
                        maxLength ? maxCharactersId : ''
                    }`.trim()}
                    {...props}
                />

                {!!maxLength && (
                    <VisuallyHidden id={maxCharactersId}>
                        {`${translations.Textarea.MaxCharacters(maxLength)}`}
                    </VisuallyHidden>
                )}

                {showCounter && (
                    <>
                        <StyledCounter aria-hidden>{counter}</StyledCounter>
                        {!!maxLength && <AriaCounter id={counterId} value={counter} />}
                    </>
                )}
            </TextareaWrapper>
            {renderError && renderError()}
        </ComponentWrapper>
    )
}

Textarea.displayName = 'Textarea'

interface AriaCounterProps {
    value: number
    id: string
}

const StyledCounter = styled(Text)`
    position: absolute;
    right: ${tokens.spacing6};
    bottom: 0;
    font-size: ${tokens.fontSmall};
`

/*
 * The AriaCounter component is designed to provide an accessible way
 * to announce a delayed update of a counter value to screen readers.
 * It utilizes an aria-live region to communicate changes in the number
 * of remaining characters without being visually displayed.
 * It introduces a delay of 1500ms (1.5 seconds) before updating the counter value for screen readers.
 * This delay helps reduce the frequency of announcements, which can enhance usability and avoid
 * overwhelming users when values change rapidly (e.g., while typing).
 */

const AriaCounter = ({value, id}: AriaCounterProps) => {
    const translations = useLocalisation()
    const [delayedCounter, setDelayedCounter] = useState<number>(value)

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDelayedCounter(value)
        }, 1500)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <VisuallyHidden id={id} aria-live="polite" aria-atomic="true">
            {translations.Textarea.RemainingCharacters(delayedCounter)}
        </VisuallyHidden>
    )
}
