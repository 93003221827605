import type React from 'react'
import styled from 'styled-components'

import {tokens} from '../../tokens'
import {px} from '../../utils/px'

export type TagVariants = 'pink' | 'yellow' | 'green' | 'purple' | 'gray'

export interface TagProps extends React.HTMLAttributes<HTMLSpanElement> {
    /**
     * Choose what color the tag should be
     */
    variant: TagVariants
    /**
     * Provide the tag with a label
     */
    children: React.ReactNode
    /**
     * Add an icon to the left of the label
     */
    Icon?: React.FC<{size?: any; color?: any}>
}

const getBackgroundColor = ($variant: TagVariants) => {
    if ($variant === 'pink') {
        return tokens.colorBackgroundPresentationalPink
    } else if ($variant === 'yellow') {
        return tokens.colorBackgroundPresentationalYellow
    } else if ($variant === 'green') {
        return tokens.colorBackgroundPresentationalGreen
    } else if ($variant === 'purple') {
        return tokens.colorBackgroundPresentationalPurple
    }
    return tokens.colorBackgroundPresentationalGray
}

const Wrapper = styled('span')<{$variant: TagVariants; $bordered?: Boolean}>`
    box-sizing: border-box;
    min-height: ${px(22)};
    display: inline-flex;
    align-items: center;
    padding: ${tokens.spacing2} ${tokens.spacing6};
    gap: ${tokens.spacing4};
    border-radius: ${tokens.arc4};
    background-color: ${({$variant}) => getBackgroundColor($variant)};
    font-weight: ${tokens.fontWeightRegular};
    font-size: ${tokens.fontSmall};
    color: ${tokens.colorContentStatic};
    user-select: none;
    white-space: nowrap;
`

export const Tag = ({variant = 'pink', children, Icon, ...props}: TagProps) => {
    return (
        <Wrapper $variant={variant} data-telescope="tag" {...props}>
            {Icon && <Icon size={16} color={tokens.colorContentStatic} />}
            {children}
        </Wrapper>
    )
}
