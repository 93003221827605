import * as RadixDialog from '@radix-ui/react-dialog'
import {Slot} from '@radix-ui/react-slot'
import {useId} from '@reach/auto-id'
import {AnimatePresence, motion, useReducedMotion} from 'framer-motion'
import * as React from 'react'
import {RemoveScroll} from 'react-remove-scroll'
import styled from 'styled-components'
import {useMediaQuery} from 'usehooks-ts'

import {Close as CloseIcon} from '@pleo-io/telescope-icons'

import {tokens} from '../../tokens'
import {useDialogTriggerContext} from '../../utils/dialog-trigger-provider'
import {px} from '../../utils/px'
import {Box} from '../box'
import {ButtonGroup} from '../button'
import {IconButton} from '../icon-button'
import {Inline} from '../inline'
import {Stack} from '../stack'
import {Text} from '../text'

const breakpoints = {
    medium: px(450)
}

export interface DrawerContextValue {
    isScrolled: boolean
    setIsScrolled: (isScrolled: boolean) => void
    isOpen: boolean
    drawerId: string
}

export const DrawerContext = React.createContext<DrawerContextValue | undefined>(undefined)

export const RadixDialogRoot = RadixDialog.Root

function useDrawerContext() {
    const context = React.useContext(DrawerContext)
    if (!context) {
        throw new Error('useDrawerContext must be used within a DrawerProvider')
    }
    return context
}

interface DrawerProps {
    /**
     *  Drawer trigger and content
     */
    children?: React.ReactNode
    /**
     * The open state of the Drawer. Must be used in conjunction with onDismiss.
     */
    isOpen: boolean
    /**
     * Function called when the drawer is closed by any means (close button or click outside).
     */
    onDismiss: () => void
    /**
     * The unique id of the drawer. This is used to link the trigger to the drawer, and refocus
     * the trigger automatically when the drawer is closed.
     */
    drawerId: string
}

export const Drawer = ({isOpen, onDismiss, children, drawerId}: DrawerProps) => {
    const [isScrolled, setIsScrolled] = React.useState(false)
    const {refocusTrigger, setIsDialogOpen} = useDialogTriggerContext()

    // When the drawer is opened or closed, we update the dialog trigger context
    // so we can show the correct aria labels on the triggers
    React.useEffect(() => {
        setIsDialogOpen({dialogId: drawerId, isOpen})
    }, [isOpen, setIsDialogOpen, drawerId])

    return (
        <RadixDialog.Root
            onOpenChange={(open) => {
                if (!open) {
                    onDismiss()
                    refocusTrigger({dialogId: drawerId})
                }
            }}
            open={isOpen}
            modal={false}
        >
            <DrawerContext.Provider value={{isScrolled, setIsScrolled, isOpen, drawerId}}>
                {children}
            </DrawerContext.Provider>
        </RadixDialog.Root>
    )
}

const overlayAnimationVariants = {
    initial: {
        x: 0,
        opacity: 0,
        transition: {
            opacity: {
                duration: tokens.motionDurationModerate,
                easing: tokens.motionEasingExitScreen
            }
        }
    },
    enter: ({shouldReduceMotion}: {shouldReduceMotion: boolean}) => ({
        x: 0,
        opacity: 1,
        transition: {
            opacity: {
                delay: shouldReduceMotion ? 0 : tokens.motionDurationFast,
                duration: tokens.motionDurationSlow,
                easing: tokens.motionEasingEnterScreen
            }
        }
    })
}

const panelAnimationVariants = {
    initial: ({shouldReduceMotion}: {shouldReduceMotion: boolean}) => ({
        x: '100%',
        // we only want to transition the opacity if the user has set prefers-reduced-motion
        opacity: shouldReduceMotion ? 0 : 1,
        transition: {
            x: {
                duration: shouldReduceMotion ? 0 : tokens.motionDurationModerate,
                easing: tokens.motionEasingExitScreen
            },
            opacity: {
                duration: shouldReduceMotion ? tokens.motionDurationModerate : 0,
                easing: tokens.motionEasingExitScreen
            }
        }
    }),
    enter: ({shouldReduceMotion}: {shouldReduceMotion: boolean}) => ({
        x: 0,
        opacity: 1,
        transition: {
            x: {
                duration: shouldReduceMotion ? 0 : tokens.motionDurationSlow,
                easing: tokens.motionEasingEnterScreen
            },
            opacity: {
                duration: shouldReduceMotion ? tokens.motionDurationSlow : 0,
                easing: tokens.motionEasingEnterScreen
            }
        }
    })
}

interface DrawerPanelProps {
    /**
     *  Drawer content
     */
    children?: React.ReactNode
    /**
     * Called when the drawer is opened. Use it to set focus in the most relevant focusable element in the drawer.
     * If this is not provided, the first focusable element in the drawer (usually the Close button) will be focussed by default.
     */
    onOpenFocusRef?: React.RefObject<HTMLElement>
    className?: string
}

const DrawerPanel = ({children, onOpenFocusRef, className}: DrawerPanelProps) => {
    const {isOpen, drawerId} = useDrawerContext()
    const shouldReduceMotion = useReducedMotion()
    const overlayRef = React.useRef<HTMLDivElement>(null)

    return (
        <AnimatePresence>
            {isOpen ? (
                <RadixDialog.Portal forceMount>
                    <AnimatedOverlay
                        initial="initial"
                        animate="enter"
                        exit="initial"
                        variants={overlayAnimationVariants}
                        custom={{shouldReduceMotion}}
                        ref={overlayRef}
                    />
                    <RemoveScroll allowPinchZoom>
                        <AnimatedDrawerPanel
                            id={drawerId}
                            initial="initial"
                            animate="enter"
                            exit="initial"
                            variants={panelAnimationVariants}
                            custom={{shouldReduceMotion}}
                            onOpenAutoFocus={
                                onOpenFocusRef
                                    ? (e) => {
                                          e.preventDefault()
                                          onOpenFocusRef.current?.focus()
                                      }
                                    : undefined
                            }
                            onInteractOutside={(e) => {
                                if (e.target !== overlayRef.current) {
                                    e.preventDefault()
                                }
                            }}
                            className={className}
                        >
                            {children}
                        </AnimatedDrawerPanel>
                    </RemoveScroll>
                </RadixDialog.Portal>
            ) : null}
        </AnimatePresence>
    )
}

const Overlay = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${tokens.colorBackgroundStaticBackdrop};
`

const AnimatedOverlay = motion(Overlay)

const DrawerPanelBase = styled(RadixDialog.Content)`
    container: drawer / size;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: ${px(600)};
    max-width: 100%;
    background: ${tokens.colorBackgroundStatic};

    &,
    & > * {
        box-sizing: border-box;
    }
`

const AnimatedDrawerPanel = motion(DrawerPanelBase)

interface TriggerProps {
    /**
     * The trigger for the drawer. This can be any element that can be clicked.
     */
    children?: React.ReactNode
    /**
     * The unique id of the drawer that this trigger should open. This is used to
     * link the trigger with the drawer, and refocus the trigger automatically
     * when the drawer is closed.
     */
    drawerId: string
}

const Trigger = ({children, drawerId}: TriggerProps) => {
    // useId is only returns a value when the component is mounted,
    // so we default it to an empty string to prevent it ever being undefined
    const triggerId = useId() ?? ''
    const {
        registerTrigger,
        unregisterTrigger,
        registerTriggerInteraction,
        getIsDialogOpen,
        triggerRegistry
    } = useDialogTriggerContext()
    const triggerRef = React.useRef<HTMLElement>(null)

    const isOpen = getIsDialogOpen(drawerId)
    const isTriggerThatOpenedDialog = triggerId === triggerRegistry[drawerId]?.triggerToRefocusId

    React.useEffect(() => {
        // register the trigger when it mounts
        registerTrigger({dialogId: drawerId, triggerId, triggerRef})

        // unregister the trigger when it unmounts
        return () => {
            unregisterTrigger({dialogId: drawerId, triggerId})
        }
    }, [registerTrigger, unregisterTrigger, triggerId, drawerId])

    return (
        <Slot
            ref={triggerRef}
            onClick={() => {
                registerTriggerInteraction({triggerId, dialogId: drawerId})
            }}
            aria-haspopup="dialog"
            // aria-expanded is only true if the dialog is open and this trigger opened it
            aria-expanded={isOpen && isTriggerThatOpenedDialog ? 'true' : 'false'}
            aria-controls={drawerId}
        >
            {children}
        </Slot>
    )
}

interface ActionBarProps {
    /**
     * Optional additional actions to display in the ActionBar.
     * Spacing is handled by the container.
     * The close button will always show.
     */
    children?: React.ReactNode
    /**
     * The aria-label for the Close button
     */
    closeButtonAriaLabel: string
}

const ActionBar = ({children, closeButtonAriaLabel}: ActionBarProps) => {
    return (
        <ActionBarContainer>
            <RadixDialog.Close asChild>
                <IconButton
                    variant="secondary"
                    Icon={CloseIcon}
                    tooltipProps={{
                        dangerouslyOmitTooltip: true,
                        'aria-label': closeButtonAriaLabel
                    }}
                    size="medium"
                />
            </RadixDialog.Close>
            {children ? <Inline space={16}>{children}</Inline> : null}
        </ActionBarContainer>
    )
}

const ActionBarContainer = styled(Inline).attrs({
    justifyContent: 'space-between',
    alignItems: 'center'
})`
    padding: ${tokens.spacing16} ${tokens.spacing24};

    @container drawer (width <= ${breakpoints.medium}) {
        padding: ${tokens.spacing12} ${tokens.spacing12};
    }
`

interface HeaderProps {
    /**
     * The content of the drawer header
     */
    children?: React.ReactNode
}

const Header = ({children}: HeaderProps) => {
    const {isScrolled} = useDrawerContext()
    return <HeaderContainer $isScrolled={isScrolled}>{children}</HeaderContainer>
}

const HeaderContainer = styled(Inline).attrs({
    space: 24,
    alignItems: 'center'
})<{$isScrolled: boolean}>`
    padding: ${({$isScrolled}) =>
        $isScrolled
            ? `${tokens.spacing4} ${tokens.spacing60} ${tokens.spacing24}`
            : `${tokens.spacing12} ${tokens.spacing60} ${tokens.spacing24}`};
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    border-style: solid;
    border-width: 0;
    border-bottom-width: ${tokens.sizeBorderDefault};
    border-color: ${({$isScrolled}) => ($isScrolled ? tokens.colorBorderStatic : 'transparent')};
    transition: border-color ${tokens.motionWithinSmallShort},
        padding ${tokens.motionWithinSmallLong};

    @container drawer (width <= ${breakpoints.medium}) {
        padding: ${({$isScrolled}) =>
            $isScrolled
                ? `${tokens.spacing4} ${tokens.spacing24} ${tokens.spacing12}`
                : `${tokens.spacing12} ${tokens.spacing24}`};
    }

    /* if the user has set prefers-reduced-motion, we don't want to animate the padding,
     or make it jump on scroll, so we just keep it consistent */
    @media (prefers-reduced-motion) {
        transition: none;
        padding: ${tokens.spacing12} ${tokens.spacing60} ${tokens.spacing24};

        @container drawer (width <= ${breakpoints.medium}) {
            padding: ${tokens.spacing12} ${tokens.spacing24};
        }
    }
`

interface HeaderImageProps {
    /**
     * The image to display in the header. Could also be an Avatar.
     */
    children?: React.ReactNode
}

const HeaderImage = ({children}: HeaderImageProps) => {
    return <HeaderImageContainer>{children}</HeaderImageContainer>
}

const HeaderImageContainer = styled(Box)`
    max-width: ${px(72)};
    width: 100%;

    > * {
        max-width: 100%;
    }
`

interface TitleProps {
    /**
     * The title of the drawer
     */
    title: string
    /**
     * The optional subtitle for the drawer header
     */
    subtitle?: string
}

const Title = ({title, subtitle}: TitleProps) => {
    const isSm = useMediaQuery('(max-width: 767px)')

    return (
        <Stack>
            <RadixDialog.Title asChild>
                <Text variant={isSm ? '2xlarge-accent' : '3xlarge-accent'} as="h2">
                    {title}
                </Text>
            </RadixDialog.Title>
            {subtitle ? (
                <RadixDialog.Description asChild>
                    <Text
                        variant={isSm ? 'medium-default' : 'large-accent'}
                        color="colorContentStaticQuiet"
                        as="p"
                    >
                        {subtitle}
                    </Text>
                </RadixDialog.Description>
            ) : null}
        </Stack>
    )
}

interface BodyProps {
    /**
     * The contents of the Drawer Body
     */
    children?: React.ReactNode
}

const Body = ({children}: BodyProps) => {
    const {setIsScrolled} = useDrawerContext()
    return (
        <BodyContainer
            onScroll={(e) => {
                setIsScrolled(e.currentTarget.scrollTop > 0)
            }}
        >
            {children}
        </BodyContainer>
    )
}

const BodyContainer = styled(Box)`
    flex: 1;
    overflow-y: auto;
    padding: ${tokens.spacing12} ${tokens.spacing60} ${tokens.spacing60};

    @container drawer (width <= ${breakpoints.medium}) {
        & {
            padding: ${tokens.spacing12} ${tokens.spacing24} ${tokens.spacing24};
        }
    }
`

interface FooterProps {
    /**
     * The contents of the Drawer Footer. This should only contain `Button` components, and is just a styled wrapper for `ButtonGroup`.
     */
    children?: React.ReactNode
}

const Footer = ({children}: FooterProps) => {
    return <FooterContainer>{children}</FooterContainer>
}

const FooterContainer = styled(ButtonGroup)`
    border-top: ${tokens.borderStatic};
    justify-content: flex-end;
    padding: ${tokens.spacing24};

    @container drawer (width <= ${breakpoints.medium}) {
        padding: ${tokens.spacing12} ${tokens.spacing24};
    }
`

Drawer.Panel = DrawerPanel
Drawer.Trigger = Trigger
Drawer.ActionBar = ActionBar
Drawer.Header = Header
Drawer.HeaderImage = HeaderImage
Drawer.Title = Title
Drawer.Body = Body
Drawer.Footer = Footer
