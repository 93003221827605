import styled from 'styled-components'

import {tokens} from '../../tokens'
import {px} from '../../utils/px'
import {Skeleton} from '../skeleton'

type CountProps = {skeleton?: boolean}
export const Count = styled.span
    .withConfig({shouldForwardProp: (propName: string) => propName !== 'skeleton'})
    .attrs<CountProps>((props) => ({
        ...(props.skeleton && {as: Skeleton})
    }))`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    min-width: ${px(24)};
    height: ${px(20)};
    padding: 0 ${tokens.spacing8};
    color: ${tokens.colorContentStatic};
    font-size: ${tokens.fontSmall};
    font-weight: ${tokens.fontWeightRegular};
    background: ${tokens.colorBackgroundPresentationalPink};
    border-radius: ${tokens.arc99999};
    white-space: nowrap;
    user-select: none;

    [disabled] & {
        background-color: ${tokens.colorBackgroundInteractiveDisabled};
        color: ${tokens.colorContentInteractiveDisabled};
    }
`
