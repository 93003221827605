/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import type {FC} from 'react'
import React from 'react'
import styled from 'styled-components'

import * as s from './button.styles'

import {tokens} from '../../tokens'
import {px} from '../../utils/px'
import {Box} from '../box'
import {focusRing} from '../focus-ring'
import {Inline} from '../inline'
import {Skeleton} from '../skeleton'
import type {TooltipProps} from '../tooltip'
import {Tooltip} from '../tooltip'

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'link'

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        React.RefAttributes<HTMLButtonElement> {
    /** The variant of the button style to use. */
    variant: ButtonVariant
    /** If `true` the button will use destructive styling. */
    destructive?: boolean
    /** Allow as react-router link or any relevant component, not just native html components. */
    as?: any
    /** When (and only when) adding styles to the Button component with styled-components, use this instead of `as` */
    forwardedAs?: any
    /** If `true` the button will be disabled. */
    disabled?: boolean
    /** If `true` the button will show a loading animation and hide the text. */
    loading?: boolean
    /** Will display text next to the loading animation */
    loadingText?: string
    /** When used as a router link, this will redirect the user to the specified route. */
    to?: string
    /** Inherit the parent's font-size. */
    inherit?: boolean
    /** Pass Tooltip Props to add a tooltip to the button. Accepts the same props as the tooltip component */
    tooltipProps?: Omit<TooltipProps, 'children'>
    /** If `true` the button will use the full width of its parent container */
    fullWidth?: boolean
    /**
     * Show a skeleton loading state
     * @default false
     */
    skeleton?: boolean

    // AnchorHTMLAttributes since Button can also output an Anchor HTML element
    download?: any
    href?: string
    hrefLang?: string
    media?: string
    ping?: string
    rel?: string
    target?: string
    referrerPolicy?: string

    /** Add an Icon on the left side of the text */
    IconLeft?: React.ComponentType
    /** Add an Icon on the right side of the text */
    IconRight?: React.ComponentType
}

const getBaseComponent = (props: ButtonProps): React.ElementType | undefined => {
    if (props.forwardedAs) {
        return undefined
    }

    if (props.disabled) {
        // Anchor tags cannot be disabled so output a button when this prop is set to true
        return 'button'
    } else if (props.as) {
        return props.as
    } else if (props.href) {
        return 'a'
    }

    return 'button'
}

export const getLabelFromChildren = (children: React.ReactNode) => {
    let label = ''

    React.Children.map(children, (child) => {
        if (typeof child === 'string') {
            label += child
        }
    })

    return label
}

const IconWrapper = styled(Box)`
    display: flex;
    align-items: center;

    [data-telescope='icon'] {
        width: ${px(16)};
        height: ${px(16)};
    }
`

const BaseButton = React.forwardRef((props: ButtonProps, ref) => {
    const {IconLeft, IconRight, skeleton = false} = props
    const children = props.loading ? (
        props.children
    ) : (
        <>
            {IconLeft && (
                <IconWrapper mr={8}>
                    <IconLeft />
                </IconWrapper>
            )}
            {props.children}
            {IconRight && (
                <IconWrapper ml={8}>
                    <IconRight />
                </IconWrapper>
            )}
        </>
    )

    if (props.variant === 'link' || props.variant === 'tertiary') {
        return (
            <Skeleton loading={skeleton} borderRadius={4}>
                <s.ButtonLink
                    {...props}
                    as={getBaseComponent(props)}
                    ref={ref}
                    data-telescope="button"
                >
                    {children}
                </s.ButtonLink>
            </Skeleton>
        )
    }

    return (
        <Skeleton loading={skeleton}>
            <s.BaseButton
                type={'button'}
                {...props}
                as={getBaseComponent(props)}
                ref={ref}
                data-telescope="button"
            >
                {children}
            </s.BaseButton>
        </Skeleton>
    )
})

export const Button: FC<ButtonProps> = React.forwardRef((props, ref) => {
    if (props.loading) {
        return (
            <BaseButton
                type="button"
                as={getBaseComponent(props)}
                // Since the label text is set to `visibility: hidden`, find the text
                // of the button to set the correct aria-label for accessibility
                aria-label={getLabelFromChildren(props.children)}
                ref={ref}
                {...props}
                disabled
                data-telescope="button"
            >
                {props.loadingText ? (
                    <>
                        <s.SpinningLoader />
                        <s.StyledLoadingText>{props.loadingText}</s.StyledLoadingText>
                    </>
                ) : (
                    <>
                        <s.Hidden>{props.children}</s.Hidden>
                        <s.LoadingWrapper>
                            <s.SpinningLoader />
                        </s.LoadingWrapper>
                    </>
                )}
            </BaseButton>
        )
    }
    if (props.tooltipProps?.content && props.disabled) {
        return (
            <Tooltip {...props.tooltipProps}>
                <ButtonTooltipWrapper tabIndex={0}>
                    <BaseButton {...props} ref={ref} />
                </ButtonTooltipWrapper>
            </Tooltip>
        )
    } else if (props.tooltipProps?.content) {
        return (
            <Tooltip {...props.tooltipProps}>
                <BaseButton {...props} ref={ref} />
            </Tooltip>
        )
    }
    return <BaseButton {...props} ref={ref} />
})

const ButtonTooltipWrapper = styled.div`
    width: fit-content;
    border-radius: ${tokens.arc20};
    ${focusRing('offset')};
`

Button.displayName = 'Button'

export const NakedButton = s.NakedButton

export const ButtonGroup = styled(Inline).attrs({
    space: 16,
    alignY: 'center',
    'data-telescope': 'button-group'
})``
