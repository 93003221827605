import type {
    TabsContentProps as RadixTabsContentProps,
    TabsListProps as RadixTabsListProps,
    TabsProps as RadixTabsProps,
    TabsTriggerProps
} from '@radix-ui/react-tabs'
import {Root, TabsContent, TabsList, Trigger as RadixTrigger} from '@radix-ui/react-tabs'
import type React from 'react'
import styled from 'styled-components'

import {tokens} from '../../tokens'
import {px} from '../../utils/px'
import {Count} from '../count'
import {focusRingWidth} from '../focus-ring'

const lineHeight = px(24)

type TabsProps = Omit<RadixTabsProps, 'asChild' | 'orientation' | 'dir' | 'activationMode'>
export const Tabs = (props: TabsProps) => {
    return (
        <Root data-telescope="tabs" {...props}>
            {props.children}
        </Root>
    )
}

Tabs.displayName = 'Tabs'

type TriggerProps = TabsTriggerProps
const StyledTrigger = styled(RadixTrigger)<TriggerProps>`
    display: inline-flex;
    align-items: center;
    gap: ${tokens.spacing12};
    margin: 0;
    padding: ${tokens.spacing16} 0;
    color: ${tokens.colorContentInteractiveQuiet};
    font-size: ${tokens.fontMedium};
    font-family: inherit;
    background: none;
    border: none;
    line-height: ${lineHeight};
    cursor: pointer;
    transition: color ${tokens.motionWithinSmallShort},
        border-bottom ${tokens.motionWithinSmallShort};

    &:visited {
        color: ${tokens.colorContentInteractiveQuiet};
    }

    &:hover {
        color: ${tokens.colorContentInteractiveHover};
        border-bottom: ${tokens.borderInteractiveHover};
    }

    [data-js-focus-visible] &:focus {
        /* Fallback for Windows High Contrast Mode, as it hides box-shadows */
        outline: ${focusRingWidth} solid transparent;
    }

    [data-js-focus-visible] &[data-focus-visible-added]:focus {
        box-shadow: inset 0 ${px(-4)} 0 0 ${tokens.colorShadowFocus};
        transition: ${tokens.motionWithinSmallShort};
        transition-property: box-shadow;
    }

    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    border-bottom: ${tokens.sizeBorderDefault} solid transparent;

    ${({disabled}) =>
        disabled &&
        `
        cursor: not-allowed;
        color: ${tokens.colorContentInteractiveDisabled};
        border-bottom: none;

        &:hover {
            border-bottom: none;
            color: ${tokens.colorContentInteractiveDisabled};
        }
        `}

    &[aria-selected='true'] {
        color: ${tokens.colorContentInteractive};
        font-weight: ${tokens.fontWeightSemibold};
        border-bottom: ${tokens.borderInteractiveSelected};

        &:hover {
            border-bottom: ${tokens.borderInteractiveHover};
        }
    }
`

const StyledTriggerText = styled.span`
    line-height: ${lineHeight};

    /* See https://css-tricks.com/bold-on-hover-without-the-layout-shift/ */
    &::after {
        display: block;
        height: 0;
        overflow: hidden;
        font-weight: ${tokens.fontWeightSemibold};
        visibility: hidden;
        content: attr(data-content);
        user-select: none;
        pointer-events: none;

        @media speech {
            display: none;
        }
    }
`

type TriggerTextProps = Omit<React.HTMLAttributes<HTMLSpanElement>, 'children'> & {
    // The content/label of the trigger
    text: string
}
const TriggerText = ({text, ...rest}: TriggerTextProps) => (
    <StyledTriggerText data-content={text} {...rest}>
        {text}
    </StyledTriggerText>
)

const StyledList = styled(TabsList)`
    display: flex;
    align-items: center;

    > * + * {
        margin-left: ${tokens.spacing28};
    }
`

type TabsListProps = Omit<RadixTabsListProps, 'loop' | 'asChild'>
const TriggerList = ({children, ...rest}: TabsListProps) => {
    return <StyledList {...rest}>{children}</StyledList>
}

TriggerList.displayName = 'TriggerList'

type TabsContentProps = Omit<RadixTabsContentProps, 'forceMount'>
const Content = ({children, ...rest}: TabsContentProps) => {
    return <TabsContent {...rest}>{children}</TabsContent>
}

Content.displayName = 'Content'

type TabsCounterProps = {
    // A numeric value to display.
    counter: number
}

const TabsTriggerCounter = ({counter}: TabsCounterProps) => {
    return <Count>{counter}</Count>
}

TabsTriggerCounter.displayName = 'TabsTriggerCounter'

Tabs.Trigger = StyledTrigger
Tabs.TriggerText = TriggerText
Tabs.TriggerList = TriggerList
Tabs.Content = Content
Tabs.TriggerCounter = TabsTriggerCounter
