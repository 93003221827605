import styled from 'styled-components'
import type {FlexboxProps} from 'styled-system'
import {border, borderRadius, flexbox, space} from 'styled-system'

import type {SpacingValues} from '../../tokens'
import {px} from '../../utils/px'
import type {BoxProps} from '../box'

type Align = 'left' | 'right' | 'center'

export interface StackProps extends FlexboxProps, BoxProps {
    /**
     * The space between each item. Default 0.
     */
    space?: 0 | SpacingValues

    align?: Align
    stretch?: boolean
}

const alignValues: {[key in Align]: string} = {
    left: 'start',
    center: 'center',
    right: 'end'
}

const TRANSIENT_PROPS = [
    'space',
    'align',
    'stretch',
    ...flexbox.propNames!,
    ...space.propNames!,
    ...border.propNames!,
    ...borderRadius.propNames!
]
const shouldForwardProp = (prop: string) => !TRANSIENT_PROPS.includes(prop)

export const Stack = styled.div
    .withConfig({shouldForwardProp})
    .attrs<StackProps>(({space: gapSpace}) => ({
        space: gapSpace ?? 0,
        'data-telescope': 'stack'
    }))<StackProps>`
    display: grid;
    grid-gap: ${({space: gapSpace}) => px(gapSpace || 0)};
    align-content: start;
    justify-items: ${({stretch, align = 'left'}) => (stretch ? 'stretch' : alignValues[align])};
    box-sizing: border-box;
    width: 100%;

    ${space}
    ${flexbox}
`

Stack.displayName = 'Stack'
