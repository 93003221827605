import tokensDefault from '@pleo-io/telescope-tokens'

export {
    __classNameColorSchemeDark,
    colorSchemeLight,
    color,
    duration,
    fontSize,
    fontWeight,
    lineHeight,
    opacity,
    radius,
    spacing,
    transition,
    zIndex
} from '@pleo-io/telescope-tokens'
export type {
    SpacingValues,
    LineHeightValues,
    RadiusValues,
    OpacityValues,
    ZIndexValues,
    FontWeightValues,
    FontSizeValues
} from '@pleo-io/telescope-tokens'
export type {CustomColorSchemeToken} from './custom'
export {createCustomColorSchemeTokensGlobalStyle, customColorSchemeTokens} from './custom'
export const tokens = tokensDefault
