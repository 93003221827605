import styled from 'styled-components'

import {ChevronDown} from '@pleo-io/telescope-icons'

import {tokens} from '../../tokens'
import {px} from '../../utils/px'
import {focusRingRegularStyle} from '../focus-ring'
import {Inline} from '../inline'

export const Wrapper = styled.div<{$maxWidth?: string}>`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacing6};
    width: 100%;
    max-width: ${({$maxWidth}) => $maxWidth};

    * {
        box-sizing: border-box;
    }
`

interface MultiValueRemoveProps {
    $isDisabled?: boolean
}

export const MultiValueRemove = styled.div<MultiValueRemoveProps>`
    display: ${({$isDisabled}) => ($isDisabled ? 'none' : 'flex')};
    align-items: center;
    justify-content: center;
    padding: ${tokens.spacing4};
    background-color: ${tokens.colorBackgroundPresentationalPink};
    border-radius: 0 ${tokens.arc4} ${tokens.arc4} 0;

    &:focus-within {
        ${focusRingRegularStyle};
    }

    [role='button'] {
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${px(20)};
        height: ${px(20)};
        background-color: ${tokens.colorBackgroundPresentationalPink};
        border-radius: ${tokens.arc4};
        cursor: pointer;
    }

    [role='button']:hover {
        border: ${tokens.sizeBorderThick} solid ${tokens.colorBorderInteractiveHover};
    }
`

interface StyledChevronProps {
    $isDisabled?: boolean
    $menuIsOpen?: boolean
}

function getDropDownIndicatorColor(props: StyledChevronProps) {
    if (props.$isDisabled) {
        return tokens.colorContentInteractiveDisabled
    }

    if (props.$menuIsOpen) {
        return tokens.colorContentInteractive
    }

    return tokens.colorContentInteractiveQuiet
}

export const StyledChevron = styled(ChevronDown)<StyledChevronProps>`
    color: ${getDropDownIndicatorColor};
    transform: ${(props) => (props.$menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)')};

    &:hover {
        color: ${tokens.colorContentInteractiveHover};
    }
`

export const IconWrapper = styled.span`
    display: flex;
    align-items: center;

    [data-telescope='icon'] {
        width: ${px(16)};
        height: ${px(16)};
    }
`

export const ValueWrapper = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
`

export const CreateableWrapper = styled(Inline).attrs({space: 8})`
    word-break: 'break-word';
`
