import {ErrorMessage, useField} from 'formik'

import {FormControl} from '../form-control'
import type {SelectTypes} from '../select/select'
import {Select} from '../select/select'

export const FormikSelect = (
    props: SelectTypes.SelectProps<SelectTypes.Option> & {name: string}
) => {
    const [field, meta, helpers] = useField(props.name)

    return (
        <Select
            isInvalid={!!(meta.error && meta.touched)}
            renderError={() => <ErrorMessage name={props.name} component={FormControl.Error} />}
            onBlur={(event) => {
                helpers.setTouched(true)
                if (props.onBlur) {
                    props.onBlur(event)
                }
            }}
            onChange={(newValue, actionMeta) => {
                if (actionMeta.action === 'clear' && props.isMulti) {
                    return helpers.setValue([])
                }

                if (actionMeta.action === 'clear') {
                    return helpers.setValue(null)
                }

                return helpers.setValue(newValue)
            }}
            value={field.value}
            data-telescope="formik-select"
            {...props}
        />
    )
}
