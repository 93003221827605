import type {HTMLAttributes} from 'react'
import React, {forwardRef} from 'react'
import {createContext, useContext} from 'react'
import type {BackgroundColorProps, MarginProps} from 'styled-system'

import * as s from './avatar.styles'
import type {AvatarSize} from './types'
import {useImage} from './use-image'

import {Skeleton} from '../skeleton'
import {VisuallyHidden} from '../visually-hidden'

export const AVATAR_DEFAULT_SIZE = 48

const Context = createContext<{size: AvatarSize}>({size: AVATAR_DEFAULT_SIZE})
const Provider = Context.Provider
const useAvatar = () => useContext(Context)

export interface AvatarProps
    extends MarginProps,
        BackgroundColorProps,
        HTMLAttributes<HTMLDivElement> {
    /**
     * The full name of the person in the avatar.
     */
    name?: string
    /**
     * Url for the image to display as the avatar
     */
    src?: string
    /**
     * The size (height + width) of the avatar
     */
    size?: AvatarSize
    /**
     * Can be passed instead of an image `src`
     */
    icon?: React.ReactElement
    /**
     * Function to get the initials to display
     */
    getInitials?: (name: string) => string
    /**
     * Adds a white outline around the avatar. Used when avatars are displayed on a background that is not white.
     */
    outlined?: boolean
    /**
     * The variant of the avatar
     */
    state?: 'default' | 'disabled' | 'placeholder'
    /**
     * Show a skeleton loading state
     * @default false
     */
    skeleton?: boolean
}

export const Avatar = ({
    name,
    src,
    size = AVATAR_DEFAULT_SIZE,
    icon,
    getInitials = initials,
    outlined,
    children,
    state = 'default',
    backgroundColor,
    color,
    bg,
    skeleton = false,
    ...props
}: AvatarProps) => {
    // Picking color props for styled system and making sure undefined are filtered out
    // See https://linear.app/pleo/issue/DO-3543/improve-avatar-styled-system-props-api
    const colorProps = Object.fromEntries(
        Object.entries({backgroundColor, color, bg}).filter(([, value]) => value)
    )
    return (
        <Skeleton loading={skeleton}>
            <s.AvatarCircle $size={size} $outlined={outlined} {...props} data-telescope="avatar">
                <s.AvatarInnerWrapper $name={name} $state={state} {...colorProps}>
                    <AvatarImage
                        name={name}
                        getInitials={getInitials}
                        src={src}
                        icon={icon}
                        size={size}
                    />
                </s.AvatarInnerWrapper>
                <Provider value={{size}}>{children}</Provider>
            </s.AvatarCircle>
        </Skeleton>
    )
}

Avatar.displayName = 'Avatar'

function initials(name: string) {
    const [firstName, lastName] = name.toUpperCase().split(' ')
    return firstName && lastName
        ? `${firstName.charAt(0)}${lastName.charAt(0)}`
        : firstName.charAt(0)
}

type AvatarNameProps = Pick<AvatarProps, 'name' | 'getInitials'> & HTMLAttributes<HTMLDivElement>

const AvatarName = ({name, getInitials}: AvatarNameProps) => {
    const hasName = !!name
    return (
        <div {...(hasName && {role: 'img', 'aria-label': name})}>
            {hasName ? getInitials?.(name) : null}
        </div>
    )
}

type AvatarImageProps = Pick<AvatarProps, 'src' | 'getInitials' | 'name' | 'icon' | 'size'>

const AvatarImage = ({
    icon,
    name,
    getInitials,
    src,
    size = AVATAR_DEFAULT_SIZE
}: AvatarImageProps) => {
    const status = useImage({src})

    const hasLoaded = status === 'loaded'

    /**
     * Fallback avatar applies under 2 conditions:
     * - If `src` was passed and the image has not loaded or failed to load
     * - If `src` wasn't passed
     *
     * In this case, we'll show either the initials avatar
     */
    const showFallback = !src || !hasLoaded

    if (icon) {
        return React.cloneElement(icon, {
            role: 'img',
            size: s.iconSizeMapping[size]
        })
    }

    if (showFallback) {
        return <AvatarName name={name} getInitials={getInitials} />
    }

    return <s.AvatarImage $name={name} src={src} />
}

export type BadgeVariant = 'neutral' | 'attention'

type AvatarBadgeProps = HTMLAttributes<HTMLElement> & {
    /**
     * The icon to be shown inside the badge
     */
    icon: React.ReactElement
    /**
     * The visually hidden text alternative for the badge icon
     */
    visuallyHiddenText: string
    /**
     * Controls the appearance/style of the badge
     */
    variant?: BadgeVariant
    /**
     * The HTML element to render as
     */
    as?: 'div' | 'button'
}

export const AvatarBadge = forwardRef<HTMLDivElement, AvatarBadgeProps>(
    ({icon, as = 'div', variant = 'neutral', visuallyHiddenText, ...rest}, ref) => {
        const {size} = useAvatar()

        const clonedIcon = React.cloneElement(icon, {
            size: 16
        })
        return (
            <s.AvatarBadge as={as} ref={ref} $size={size} $variant={variant} {...rest}>
                {clonedIcon}
                <VisuallyHidden>{visuallyHiddenText}</VisuallyHidden>
            </s.AvatarBadge>
        )
    }
)

Avatar.Badge = AvatarBadge
