import type {ChangeEvent, FC, FocusEvent, InputHTMLAttributes, KeyboardEvent} from 'react'
import {useRef, useState} from 'react'
import styled from 'styled-components'

import {Locale, useDebouncedValue, useFormatCurrency} from './currency-input.hooks'

import type {AriaProps, InputProps} from '../input'
import {BaseInput, Input} from '../input'

interface CurrencyProps {
    /**
     * The currency of the input.
     */
    currency?: string

    /**
     * The locale of the input.
     * @default en-GB
     */
    locale?: string

    /**
     * The config for the number formatter.
     * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat for docs
     */
    formatterConfig?: Intl.NumberFormatOptions
}

export type CurrencyInputProps = Omit<InputProps, 'renderInput'> & CurrencyProps

const StyledBaseInput = styled(BaseInput)`
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        margin: 0;
        appearance: none;
    }

    appearance: textfield;
`

export const CurrencyInput: FC<CurrencyInputProps> = ({
    currency,
    locale = Locale.en_GB,
    formatterConfig,
    ...props
}) => {
    const [isFocused, setIsFocused] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)
    const isFocusedDebounced = useDebouncedValue(isFocused, 1)

    const combinedRefs = props.innerRef || inputRef

    // Prevent users from accidentally changing the input with a mouse wheel scroll
    combinedRefs?.current?.addEventListener('wheel', (event) => event.preventDefault(), {
        passive: false
    })

    const formatAmount = useFormatCurrency(locale, currency, formatterConfig)

    let value = props.value
    // If value is an empty string and placeholder is provided, we don't want to cast to Number
    if (!(value === '' && props.placeholder) && !isFocused) {
        value = formatAmount(Number(props.value))
    }

    return (
        <Input
            renderInput={(
                ariaProps: AriaProps,
                inputProps: InputHTMLAttributes<HTMLInputElement>
            ) => (
                <>
                    <StyledBaseInput
                        {...ariaProps}
                        {...inputProps}
                        ref={combinedRefs}
                        // change input type after unformatting the value prop
                        // otherwise this causes unwanted addition of ,00 decimals to input value on focus
                        type={isFocusedDebounced ? 'number' : 'text'}
                        step="any"
                        inputMode="numeric"
                        value={value}
                        // eslint-disable-next-line consistent-return
                        onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                            // Prevents number being accidentally incremented by pressing the arrow buttons
                            if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                                return event.preventDefault()
                            }
                        }}
                        onFocus={(event: FocusEvent<HTMLInputElement>) => {
                            setIsFocused(true)
                            if (props.onFocus) {
                                props.onFocus(event)
                            }
                        }}
                        onBlur={(event: FocusEvent<HTMLInputElement>) => {
                            setIsFocused(false)
                            if (props.onBlur) {
                                props.onBlur(event)
                            }
                        }}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            if (!isFocused) {
                                return
                            }
                            if (props.onChange) {
                                props.onChange(event)
                            }
                        }}
                        data-telescope="currency-input"
                    />
                    <input type="hidden" name={props.name} value={props.value} />
                </>
            )}
            innerRef={combinedRefs}
            {...props}
        />
    )
}

CurrencyInput.displayName = 'CurrencyInput'
