import styled, {css} from 'styled-components'

import tokens from '@pleo-io/telescope-tokens'

import type {BannerVariant} from './banner'

import {px} from '../../utils/px'
import {ButtonLink} from '../button/button.styles'
import {StyledLink} from '../link'

export const BannerBase = styled.div<{$variant: BannerVariant; $loud?: boolean}>`
    width: 100%;
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }

    ${({$variant, $loud}) => {
        const stripeMix = stripeColorMix[$variant]
        return css`
            color: ${tokens.colorContentStatic};
            background-color: ${tokens.colorBackgroundStatic};
            border-bottom: ${tokens.sizeBorderDefault} solid ${tokens.colorBorderStatic};

            &::before {
                content: '';
                display: block;
                height: ${px(4)};
                width: 100%;
                background: ${tokens.colorBorderStatic};
                ${$loud &&
                css`
                    background: repeating-linear-gradient(
                        45deg,
                        ${tokens.colorBorderStatic},
                        ${tokens.colorBorderStatic} ${px(16)},
                        color-mix(in srgb, ${tokens.colorBorderStatic}, ${stripeMix}) ${px(16)},
                        color-mix(in srgb, ${tokens.colorBorderStatic}, ${stripeMix}) ${px(32)}
                    );
                `}
            }
        `
    }}
`

export const BannerContent = styled.div`
    flex: 1;

    a,
    ${StyledLink}, ${ButtonLink} {
        font-weight: ${tokens.fontWeightMedium};
        text-decoration: underline;
    }
`

const stripeColorMix: {
    [K in BannerVariant]: string
} = {
    negative: 'transparent 65%',
    warning: 'black 25%',
    discover: 'transparent 55%',
    neutral: 'black 50%',
    info: 'transparent 65%',
    positive: 'transparent 60%'
}
