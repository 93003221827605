import type {DataAttributes} from 'styled-components'
import {styled} from 'styled-components'
import type {FlexboxProps} from 'styled-system'
import {flexbox, space} from 'styled-system'

import type {SpacingValues} from '../../tokens'
import {px} from '../../utils/px'
import type {BoxProps} from '../box'

export interface Props extends FlexboxProps, BoxProps {
    /**
     * The space between each item. Default 0. Shorthand for `columnGap` and `rowGap`.
     */
    gap?: 0 | SpacingValues
    /**
     * The space between each column. Default 0.
     */
    columnGap?: 0 | SpacingValues
    /**
     * The space between each row. Default 0.
     */
    rowGap?: 0 | SpacingValues
}

type FlexProps = Props & DataAttributes

const TRANSIENT_PROPS = ['gap', 'columnGap', 'rowGap', ...flexbox.propNames!, ...space.propNames!]
const shouldForwardProp = (propName: string) => !TRANSIENT_PROPS.includes(propName)

export const Flex = styled.div.withConfig({shouldForwardProp}).attrs<FlexProps>(() => ({
    'data-telescope': 'flex'
}))<FlexProps>`
    display: flex;
    gap: ${({gap}) => gap && px(gap)};
    column-gap: ${({columnGap}) => columnGap && px(columnGap)};
    row-gap: ${({rowGap}) => rowGap && px(rowGap)};

    ${flexbox}
    ${space}
`

Flex.displayName = 'Flex'
