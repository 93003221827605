import '@pleo-io/telescope-tokens/dist/spacing.css'
import '@pleo-io/telescope-tokens/dist/color.css'
import '@pleo-io/telescope-tokens/dist/color-scheme-light.css'
import '@pleo-io/telescope-tokens/dist/color-scheme-dark.css'
import '@pleo-io/telescope-tokens/dist/rgba.css'
import '@pleo-io/telescope-tokens/dist/opacity.css'
import '@pleo-io/telescope-tokens/dist/radius.css'
import '@pleo-io/telescope-tokens/dist/font-size.css'
import '@pleo-io/telescope-tokens/dist/font-weight.css'
import '@pleo-io/telescope-tokens/dist/line-height.css'
import '@pleo-io/telescope-tokens/dist/duration.css'
import '@pleo-io/telescope-tokens/dist/transition.css'
import '@pleo-io/telescope-tokens/dist/z-index.css'
import '@pleo-io/telescope-tokens/dist/border.css'

import {CustomColorSchemeTokensGlobalStyle} from '../../tokens/custom'
import {DialogTriggerContextProvider} from '../../utils/dialog-trigger-provider'
import type {LocalisationContextType} from '../../utils/localisation'
import {LocalisationProvider} from '../../utils/localisation'
import {TooltipProvider} from '../tooltip'

// For usage in e.g. storybook, testing setup, and our website
export const __dummyTelescopeProviderTranslations: LocalisationContextType = {
    Callout: {
        CloseButton: 'Dismiss'
    },
    Modal: {
        CloseButton: 'Close'
    },
    FormControl: {
        Error: 'Error'
    },
    Loading: {
        LoadingLabel: 'Loading'
    },
    ModalSplit: {
        BackButton: 'Back',
        StepButton: 'Step'
    },
    statusLabels: {
        neutral: 'Information',
        info: 'Information',
        positive: 'Success',
        warning: 'Warning',
        negative: 'Error',
        discover: 'New'
    },
    Textarea: {
        MaxCharacters: (count: number) => `You can enter up to ${count} characters`,
        RemainingCharacters: (count: number) => `You have ${count} characters remaining`
    }
}

type Props = {
    children: React.ReactNode
    translations: LocalisationContextType
}
export const TelescopeProvider = ({children, translations}: Props) => {
    return (
        <LocalisationProvider value={translations}>
            <DialogTriggerContextProvider>
                <TooltipProvider>
                    <CustomColorSchemeTokensGlobalStyle />
                    {children}
                </TooltipProvider>
            </DialogTriggerContextProvider>
        </LocalisationProvider>
    )
}
