import type {HTMLAttributes} from 'react'
import type React from 'react'

import {CircleCheck, Close, Info, Rocket, Warning} from '@pleo-io/telescope-icons'

import {BannerBase, BannerContent} from './banner.styles'

import {theme} from '../../utils'
import {useLocalisation} from '../../utils/localisation'
import type {IconButtonProps} from '../icon-button'
import {IconButton} from '../icon-button'
import {Inline} from '../inline'
import type {TextProps} from '../text'
import {Text} from '../text'
import {VisuallyHidden} from '../visually-hidden'

export type BannerVariant = 'negative' | 'warning' | 'discover' | 'neutral' | 'info' | 'positive'

export interface BannerProps {
    /**
     * Option to change the look of the Banner
     * @default 'neutral'
     */
    variant?: BannerVariant
    /**
     * Adds stripes to the top border of the banner to make it stand out more.
     * @default false
     */
    loud?: boolean
    /**
     * Icon to override the default Icon
     */
    Icon?: React.ComponentType
    /**
     * The contents of the Banner
     */
    children: React.ReactNode
    /**
     * Override the visually hidden prefix label
     */
    visuallyHiddenPrefix?: string
}

const variantIcons: Record<BannerVariant, React.ComponentType> = {
    neutral: Info,
    info: Info,
    warning: Warning,
    negative: Warning,
    positive: CircleCheck,
    discover: Rocket
}

export const Banner = ({
    variant = 'neutral',
    loud,
    children,
    className,
    Icon,
    visuallyHiddenPrefix,
    ...props
}: BannerProps & HTMLAttributes<HTMLDivElement>) => {
    const BannerIcon = Icon ?? variantIcons[variant]
    const translations = useLocalisation()

    return (
        <BannerBase
            data-telescope="banner"
            $variant={variant}
            $loud={loud}
            className={`${className || ''} ${theme[variant]}`}
            {...props}
        >
            <Inline py={8} px={24} space={8} alignItems="center">
                <BannerIcon />
                <VisuallyHidden>
                    {visuallyHiddenPrefix ?? `${translations.statusLabels[variant]}: `}
                </VisuallyHidden>
                {children}
            </Inline>
        </BannerBase>
    )
}

const BannerText = ({
    ...props
}: Omit<TextProps, 'weight' | 'align'> & {children?: React.ReactNode}) => (
    <Text weight="medium" align="left" {...props} />
)

const CloseButton = ({
    ...props
}: Omit<IconButtonProps, 'variant' | 'Icon' | 'size' | 'tooltipProps'> & {
    'aria-label': string
}) => (
    <IconButton
        css={{flexShrink: 0}}
        variant="secondary"
        size="extraSmall"
        Icon={Close}
        tooltipProps={{
            dangerouslyOmitTooltip: true,
            'aria-label': props['aria-label']
        }}
        {...props}
    />
)

Banner.Text = BannerText
Banner.Content = BannerContent
Banner.CloseButton = CloseButton
