import type {HTMLAttributes, InputHTMLAttributes, ReactNode, RefObject} from 'react'
import type React from 'react'
import styled, {css} from 'styled-components'

import {tokens} from '../../tokens'
import {focusRingFallbackStyle, focusRingWidth} from '../focus-ring'
import {Label} from '../form-control'

const borderWidth = tokens.sizeBorderDefault

export type InputVariant = 'underlined' | 'bordered'

export type AriaProps = Pick<
    InputHTMLAttributes<HTMLInputElement>,
    'aria-invalid' | 'aria-label' | 'id' | 'aria-describedby'
>

interface BaseInputProps {
    /**
     * The variant of the input, either underlined or bordered
     * @default bordered
     */
    variant?: InputVariant

    /**
     * A boolean flag that shows the input in an error state if true.
     */
    isInvalid?: boolean

    /**
     * Makes the input disabled if true
     */
    disabled?: boolean

    /**
     * Show a skeleton loading state
     * @default false
     */
    skeleton?: boolean

    /**
     * Adds aria-required="true" to the input
     */
    isRequired?: boolean
}

export interface InputProps
    extends BaseInputProps,
        Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
    /**
     * The name of the input
     */
    name: string

    /**
     * Renders label for the input
     */
    label?: string

    /**
     * A ref that points to the input
     */
    innerRef?: RefObject<HTMLInputElement>

    /**
     * A string or component that is appended to the output: ie Currency
     */
    postfix?: ReactNode

    /**
     * A string or component that is prepended to the output: ie Currency
     */
    prefix?: ReactNode

    /**
     * Render prop for error message
     */
    renderError?: Function

    /**
     * Render prop for input component.
     * Provides user with access to ariaProps and inputProps, a whitelisted set of props for the input
     */
    renderInput?: (ariaProps: AriaProps, inputProps: InputHTMLAttributes<HTMLInputElement>) => any
}

export interface InputWrapperProps extends BaseInputProps, HTMLAttributes<HTMLDivElement> {}

export function handleInputFocus(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    inputRef: React.RefObject<HTMLInputElement>,
    isDisabled: boolean
) {
    const input = inputRef?.current
    if (!input || event.target === input || isDisabled) {
        return
    }

    input.focus()
    adjustCursorBasedOnClickPosition(event, input)
}

function adjustCursorBasedOnClickPosition(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    input: HTMLInputElement
) {
    const selectableInputTypes = ['text', 'search', 'password', 'tel', 'url']
    if (!selectableInputTypes.includes(input.type)) {
        return
    }

    const isClickToLeft = event.nativeEvent.offsetX < input.offsetLeft
    if (isClickToLeft) {
        input.selectionEnd = 0
    } else {
        input.selectionStart = input.value.length
    }
}

const getHoverBorderColor = (props: InputWrapperProps): string => {
    if (props.disabled) {
        return tokens.colorBorderInteractiveDisabled
    }

    if (props.isInvalid) {
        return tokens.colorBorderNegativeHover
    }

    return tokens.colorBorderInteractiveHover
}

export const ComponentWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacing6};

    & ${Label} {
        align-self: flex-start;
    }
`

export const BaseInput = styled.input`
    grid-area: input;
    box-sizing: border-box;
    width: 100%;
    height: calc(${tokens.heightInputAndButton} - ${borderWidth} * 2);
    color: ${(props) =>
        props.disabled ? tokens.colorContentInteractiveDisabled : tokens.colorContentInteractive};
    font-weight: inherit;
    font-size: ${tokens.fontMedium};
    font-family: inherit;
    text-indent: ${tokens.sizeBorderDefault};
    background-color: ${(props) =>
        props.disabled
            ? tokens.colorBackgroundInteractiveDisabled
            : tokens.colorBackgroundInteractive};
    border: none;
    outline: none;
    transition: ${tokens.motionWithinSmallShort};
    appearance: none;

    &:disabled {
        cursor: not-allowed;
    }

    &:hover {
        color: ${(props) => !props.disabled && tokens.colorContentInteractiveHover};
    }

    &::placeholder {
        color: ${tokens.colorContentInteractivePlaceholder};
    }

    option:disabled {
        color: ${tokens.colorContentInteractiveDisabled};
    }
`

export const InputContent = styled.div``

export const Postfix = styled.div`
    grid-area: postfix;
`

export const Prefix = styled.div`
    grid-area: prefix;
`

export const underlinedWrapperVariant = css<InputWrapperProps>`
    border-bottom: ${(props) =>
        props.isInvalid ? tokens.borderNegative : tokens.borderInteractiveQuiet};

    &:focus-within {
        border-color: transparent;
        ${focusRingFallbackStyle};
        box-shadow: ${(props) =>
            `0 ${focusRingWidth} 0 0 ${
                props.isInvalid ? tokens.colorShadowFocusInvalid : tokens.colorShadowFocus
            }`};
        transition: ${tokens.motionWithinSmallShort};
        transition-property: box-shadow;
    }

    &:hover:not(:focus-within) {
        border-bottom: ${tokens.sizeBorderDefault} solid ${getHoverBorderColor};
    }
`

export const borderedWrapperVariant = css<InputWrapperProps>`
    padding-right: ${tokens.spacing12};
    padding-left: ${tokens.spacing12};
    background-color: ${(props) =>
        props.disabled
            ? tokens.colorBackgroundInteractiveDisabled
            : tokens.colorBackgroundInteractive};
    border: ${(props) => {
        if (props.disabled) {
            return tokens.borderInteractiveDisabled
        }

        if (props.isInvalid) {
            return tokens.borderNegative
        }

        return tokens.borderInteractiveQuiet
    }};
    border-radius: ${tokens.arc8};

    &:focus-within {
        border-color: transparent;
        ${focusRingFallbackStyle};
        box-shadow: ${(props) =>
            `0 0 0 ${focusRingWidth} ${
                props.isInvalid ? tokens.colorShadowFocusInvalid : tokens.colorShadowFocus
            }`};
        transition: ${tokens.motionWithinSmallLong};
        transition-property: box-shadow;
    }

    &:hover:focus-within {
        border-color: transparent;
    }

    &:hover:not(:focus-within) {
        border: ${tokens.sizeBorderDefault} solid ${getHoverBorderColor};
    }
`

const getVariants = ({variant}: InputWrapperProps) => {
    switch (variant) {
        case 'underlined':
            return underlinedWrapperVariant
        case 'bordered':
        default:
            return borderedWrapperVariant
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const InputWrapper = styled(({isInvalid, variant, disabled, ...rest}: InputWrapperProps) => (
    <div {...rest} />
))`
    position: relative;
    display: grid;
    grid-template-areas: 'prefix input postfix';
    grid-template-columns: auto 1fr auto;
    align-items: center;
    overflow: hidden;
    transition: ${tokens.motionWithinSmallLong};
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    border: ${tokens.sizeBorderDefault} solid transparent;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'text')};
    background-color: ${(props) =>
        props.disabled
            ? tokens.colorBackgroundInteractiveDisabled
            : tokens.colorBackgroundInteractive};
    ${getVariants};
`
